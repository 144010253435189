<template>
    <v-container fluid>
        <div class="pl-4 pr-0 pt-2 d-flex">
            <div class="pr-0 pt-4 left_filter">
                <setting-menu @filter_show="handleFilterShow"></setting-menu>
            </div>
            <div class="div_body pl-3 pr-0 mx-0">
                <v-form @submit.prevent="getDocumentFlowTypes()">
                    <v-btn v-show="$vuetify.breakpoint.xsOnly" :to="{name: 'document_flow_type.create'}"
                           color="indigo" fab fixed top right class="v-btn--add-form-top white--text">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                        <v-card flat class="background_color_transparent">
                            <v-card-text class="py-0">
                                <v-row class="pt-5">
                                    <v-col cols="12" sm="8" class="title_menu mt-1">
                                        {{  $t('document_flow_types') }}
                                    </v-col>
                                    <v-col v-if="!$vuetify.breakpoint.xsOnly" v-show="!filter_show" cols="12" sm="4" class="text-right">
                                        <Btn large :to="{name: 'document_flow_type.create'}" color="secondary">
                                            {{ $t('create') }}
                                        </Btn>
                                    </v-col>
                                </v-row>
                                <v-row v-if="filter_show">
                                    <v-col class="py-0" cols="12">
                                        <ValidationProvider ref="document_flow_type" rules="min:1|max:255"
                                                            v-slot="{ errors, valid }">
                                            <TextInput
                                                hide-label
                                                :error="valid === false"
                                                :error-messages="errors"
                                                id="document_flow_type"
                                                v-model="document_flow_type"
                                                :label="$t('document_flow_type_name')"
                                            ></TextInput>

                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions v-if="filter_show" class="px-4 py-6">
                                <v-row>
                                    <v-col cols="12" sm="3" md="2">

                                        <SelectInput
                                            class="main-pagination__per-page"
                                            id="pagination-variants"
                                            :value="perPage"
                                            :items="perPageItems"
                                            :label="$t('items_per_page')"
                                            :disabled="loading"
                                            @input="perPage = options.itemsPerPage = Number($event)"
                                            hide-label
                                        ></SelectInput>


                                    </v-col>
                                    <v-col cols="12" sm="9" md="10"
                                           class="d-flex justify-center justify-sm-end align-self-center">
                                        <Btn large  type="submit" color="primary">
                                            {{ $t('search') }}
                                        </Btn>

                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </ValidationObserver>
                </v-form>
                <v-card flat class="background_color_transparent">
                    <v-card-text class="pt-7">
                        <v-data-table :headers="headers" :items="document_flow_typeItems" :options.sync="options" :page.sync="page"
                                      :items-per-page="perPage" :server-items-length="totalDocumentFlowTypes" :sort-by.sync="sortBy"
                                      :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                                      :loading-text="$t('loading_please_wait')"
                                      hide-default-footer
                                      :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                      class="sortable-table elevation-0"
                                      :item-class="rowClass">
                            <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                <thead>
                                <tr class="rowHeadClass Table1">
                                    <th v-for="h in headers" :key="h.value" class="table_header">
                                        <span>{{h.text}}</span>
                                    </th>
                                </tr>
                                </thead>
                            </template>
                            <template v-slot:item.name="{ item }">
                                <div :class="item.deleted ? 'red--text' : ''">
                                    <span  class="cursor-pointer font_weight_600" @click="editDocumentFlowType(item)" >
                                        {{  item.name }}
                                        {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                                    </span>
                                    <v-icon v-if="!item.deleted" @click="deleteDocumentFlowType(item)" small>mdi-delete-outline</v-icon>
                                </div>
                            </template>


                            <template v-slot:item.exchange_rate="{ item }">
                                <div>{{ item.exchange_rate }}</div>
                            </template>
                            <template v-slot:item.letter_code="{ item }">
                                <div>{{ item.letter_code }}</div>
                            </template>
                            <template v-slot:item.character_code="{ item }">
                                <div>{{ item.character_code }}</div>
                            </template>
                        </v-data-table>
                    </v-card-text>
                    <v-card-actions class="pt-0 justify-end">
                        <v-pagination v-model="page" :total-visible="totalVisiblePage" class="pagination_table"
                                      :length="pageCount" :disabled="loading"></v-pagination>
                    </v-card-actions>
                </v-card>
            </div>
        </div>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import SettingMenu from "../components/SettingMenu";
import TextInput from "@/components/Form/TextInput";
import SelectInput from "@/components/Form/SelectInput.vue";
import Btn from "@/components/Form/Btn.vue";

export default {
    name: "DocumentFlowTypes",
    components: {
        ValidationProvider,
        ValidationObserver,
        SettingMenu,
        TextInput,
        SelectInput,
        Btn
    },
    data() {
        return {
            sortBy: "name",
            sortDir: true,
            loading: false,
            options: {},
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePage: 5,
            totalDocumentFlowTypes: 0,
            document_flow_type: null,
            document_flow_typeItems: [],
            headers: [
                {
                    text: this.$t('name'),
                    align: "left",
                    sortable: true,
                    value: "name"
                },
            ],
            filter_show: false
        };
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
    },
    mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
    },
    watch: {
        options: {
            handler() {
                this.getDocumentFlowTypes()
            },
            deep: false
        }
    },
    methods: {
        handleFilterShow(action){
            this.filter_show = action;
        },
        rowClass(){
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table1' : 'rowMobiClass';
        },
        editDocumentFlowType(item) {
            this.$router.push({
                name: 'document_flow_type.edit',
                params: {
                    id: item.id
                }
            })
        },
        async getDocumentFlowTypes(type) {
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;

            let params = {};

            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc';
            }
            if (page !== undefined) {
                if (type === 'search') {
                    params.page = 1
                } else {
                    params.page = page
                }
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }
            if (this.document_flow_type) {
                params.document_flow_type = this.document_flow_type;
            }
            await this.$http
                .get("admin/document_flow_type", {
                    params: params,
                })
                .then(res => {
                    this.document_flow_typeItems = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalDocumentFlowTypes = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.document_flow_typeItems = []
                    this.totalDocumentFlowTypes = 0
                    this.$toastr.error(this.$t('failed_to_get_list_document_flow_types'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async deleteDocumentFlowType(item) {
            if (confirm(this.$t('delete_document_flow_type'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`admin/document_flow_type/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('document_flow_type_has_been_deleted'))
                        this.getDocumentFlowTypes()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('document_flow_type_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
    }
}
</script>
